@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add to your CSS file or <style> section */
.suggestions-container {
  position: absolute; /* Important for z-index to work */
  z-index: 1000; /* Higher than the map container */
  width: 100%; /* Adjust based on your design */
  max-height: 300px; /* Optional: set a max height for the container */
  overflow-y: auto; /* Allow scrolling within the container */
  background: white; /* Ensure text is readable */
  border: 1px solid #ccc; /* A light border for the container */
  top: 100%; /* Position directly below the input field */
  left: 0; /* Align with the left edge of the input field */
}

.suggestions-item {
  padding: 8px; /* Padding for each suggestion */
  cursor: pointer; /* Indicates that items are clickable */
  border-bottom: 1px solid #eee; /* Separator between items */
}

.suggestions-item:last-child {
  border-bottom: none; /* No border for the last item */
}

.suggestions-item:hover {
  background-color: #f8f8f8; /* Light grey background on hover */
}
